import { PlusIcon } from "@heroicons/react/16/solid";
import axios from "axios";
import { useContext, useState } from "react";
import { BomContext } from "../providers/BomProvider";
import Spinner from "./Spinner";
import { Button } from "./catalyst/button";
import {
  Dialog,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "./catalyst/dialog";
import { Input } from "./catalyst/input";
import {
  Sidebar,
  SidebarHeader,
  SidebarHeading,
  SidebarSection,
} from "./catalyst/sidebar";
import { Strong } from "./catalyst/text";

function UploadBomDialog({
  bom_id,
  showUploadBom,
  setShowUploadBom,
}: {
  bom_id: number;
  showUploadBom: boolean;
  setShowUploadBom: (show: boolean) => void;
}) {
  const [file, setFile] = useState<File | undefined>(undefined);

  const uploadBom = () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("bom", bom_id.toString());
    formData.append("project", "1");
    axios
      .post("/bom/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => console.log(res))
      .catch((err) => console.error(err));

    setShowUploadBom(false);
  };

  return (
    <Dialog onClose={setShowUploadBom} open={showUploadBom}>
      <DialogTitle>Upload BOM</DialogTitle>
      <DialogDescription>Select a new BOM version.</DialogDescription>
      <DialogBody>
        <Input type="file" onChange={(e) => setFile(e.target.files?.[0])} />
        <Button className="mt-4" onClick={uploadBom} disabled={!file}>
          Upload
        </Button>
      </DialogBody>
    </Dialog>
  );
}

export default function DesignSidebar() {
  const { design } = useContext(BomContext);
  const [showUploadBom, setShowUploadBom] = useState(false);

  if (!design) {
    return (
      <Sidebar>
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      </Sidebar>
    );
  }

  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarSection className="max-lg:hidden">
          <SidebarHeading>BOM Details</SidebarHeading>
          <div className="flex flex-col space-y-4 m-2">
            <div className="flex flex-row justify-between">
              <Strong>{design.name}</Strong>
              <PlusIcon
                width={20}
                className="text-zinc-500 cursor-pointer hover:text-zinc-600"
                onClick={() => setShowUploadBom(true)}
              />
              <UploadBomDialog
                bom_id={design.id}
                showUploadBom={showUploadBom}
                setShowUploadBom={setShowUploadBom}
              />
            </div>
          </div>
        </SidebarSection>
      </SidebarHeader>
    </Sidebar>
  );
}
