import axios from "axios";
import Moment from "moment";
import { useContext, useState } from "react";
import { BomContext } from "../providers/BomProvider";
import { ProjectContext } from "../providers/ProjectProvider";
import { RetrofitContext } from "../providers/RetrofitProvider";
import Spinner from "./Spinner";
import {
  Sidebar,
  SidebarBody,
  SidebarDivider,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarSection,
} from "./catalyst/sidebar";
import { Strong, Text } from "./catalyst/text";

export default function UnitSidebar() {
  const { unit, compareTo, setCompareTo } = useContext(BomContext);
  const { project } = useContext(ProjectContext);
  const { retrofiting, lines, setRetrofiting, setLines } =
    useContext(RetrofitContext);

  const [open, setOpen] = useState(false);

  if (!unit) {
    return (
      <Sidebar>
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      </Sidebar>
    );
  }

  const complete = unit.work_orders
    ?.filter((wo) => wo.status === "complete" && wo.completed !== null)
    .sort((a, b) => Date.parse(b.completed!) - Date.parse(a.completed!));

  const incomplete = unit.work_orders?.filter(
    (wo) => wo.status === "incomplete"
  );

  const finalizeRetrofit = () => {
    const changes = lines
      ?.filter((line) => line.curr_qty !== line.prev_qty)
      .map((line) => ({
        part: line.part.id,
        qty_delta: line.curr_qty - line.prev_qty,
      }));

    console.log(changes);

    axios.post("/work_order/", {
      changes: changes,
      unit: unit.serial,
      type: "RTF",
      design: compareTo?.diff,
    });
  };

  return (
    <Sidebar>
      <SidebarHeader>
        <SidebarSection className="max-lg:hidden">
          <SidebarHeading>Unit Details</SidebarHeading>
          <div className="flex flex-col space-y-4 m-2">
            <Strong>{unit.name}</Strong>
            <div className="flex flex-row justify-between">
              <Text className="font-normal">Serial No.</Text>
              <Text className="font-normal">{unit.serial}</Text>
            </div>
            <div className="flex flex-row justify-between">
              <Text className="font-normal">Location</Text>
              <Text className="font-normal">{unit.location}</Text>
            </div>
          </div>
        </SidebarSection>
      </SidebarHeader>

      <SidebarBody>
        <SidebarSection className="max-lg:hidden">
          <SidebarHeading>Work Orders</SidebarHeading>
          {incomplete.length === 0 && (
            <Text className="ml-4">No work orders available.</Text>
          )}

          {incomplete.map((wo) => (
            <SidebarItem key={wo.id} className="cursor-pointer">
              <div className="flex flex-row justify-between w-full">
                <a href={wo.url} target="_blank" rel="noreferrer">
                  <Text className="font-normal">{wo.name}</Text>
                </a>
              </div>
            </SidebarItem>
          ))}
        </SidebarSection>

        <SidebarDivider />

        <SidebarSection className="max-lg:hidden">
          <SidebarHeading>Timeline</SidebarHeading>

          {complete?.length === 0 && (
            <Text className="ml-4">No history available.</Text>
          )}

          {complete.length > 0 && (
            <ol className="mt-4 relative border-s border-gray-200 dark:border-gray-700">
              {complete.map((wo) => (
                <li className="relative mb-10">
                  <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                  <div className="flex flex-col mt-1 ml-4 justify-between">
                    <Text className="text-gray-950">
                      {Moment(wo.completed).format("MMM D")}
                    </Text>
                    <a href={wo.url} target="_blank" rel="noreferrer">
                      <Text>{wo.name}</Text>
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          )}
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
}
