import { StarIcon as StarIconSolid } from "@heroicons/react/20/solid";
import { StarIcon as StarIconOutline } from "@heroicons/react/24/outline";
import axios from "axios";
import { useEffect, useState } from "react";
import { Design, Part, VersionLine } from "../../types/project";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import { Source, USDollar } from "./Common";

const HEADERS = [
  "",
  "Part No.",
  "Summary",
  "Name",
  "Version",
  "Qty",
  "Price",
  "Note",
  "Source",
];

export default function DesignBom({ design }: { design: Design }) {
  const [bom, setBom] = useState<VersionLine[] | undefined>(undefined);

  const toggleImportant = (part: Part) => {
    axios.patch(`/part/${part.id}/`, { important: !part.important });

    const newBom = bom?.map((l) => {
      if (l.part === part) l.part.important = !l.part.important;
      return l;
    });
    setBom(newBom);
  };

  useEffect(() => {
    const currentDesign = design.versions[0];
    axios
      .get(`/bom/?diff_id=${currentDesign.diff}`)
      .then((res) => setBom(res.data));
  }, [design]);

  if (!bom) return <></>;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {HEADERS.map((head) => (
            <TableHeader key={head}>{head}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {bom.map((l) => (
          <TableRow key={l.part.id}>
            <TableCell>
              {l.part.important ? (
                <StarIconSolid
                  className="w-5 h-5 text-yellow-500 cursor-pointer"
                  onClick={() => toggleImportant(l.part)}
                />
              ) : (
                <StarIconOutline
                  className="w-5 h-5 text-gray-300 cursor-pointer"
                  onClick={() => toggleImportant(l.part)}
                />
              )}
            </TableCell>
            <TableCell>{l.part.num}</TableCell>
            <TableCell>
              {l.part.summary !== "null" ? l.part.summary : ""}
            </TableCell>
            <TableCell>{l.part.name}</TableCell>
            <TableCell>{l.part.version}</TableCell>
            <TableCell>{l.qty}</TableCell>
            <TableCell>{USDollar.format(l.part.price)}</TableCell>
            <TableCell>{l.part.note}</TableCell>
            <TableCell>
              <Source url={l.part.url} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
