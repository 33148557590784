import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { RetrofitContext } from "../../providers/RetrofitProvider";
import { Build, DiffLine, RetrofitLine, Version } from "../../types/project";
import { Button } from "../catalyst/button";
import { Checkbox } from "../catalyst/checkbox";
import {
  Dialog,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../catalyst/dialog";
import { Input } from "../catalyst/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import { Source, USDollar, tableRowClassName } from "./Common";

const HEADERS = [
  "",
  "Part No.",
  "Summary",
  "Name",
  "Version",
  "Qty",
  "Price",
  "Note",
  "Source",
];

function FinalizeDialog({
  show,
  setShow,
  retrofitBom,
  build,
  version,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  retrofitBom: RetrofitLine[];
  build: Build;
  version: Version;
}) {
  const [note, setNote] = useState<string>("");

  const createWorkOrder = () => {
    const changes = retrofitBom
      .filter((line) => line.prev_qty !== line.curr_qty)
      .map((line) => ({
        part: line.part.id,
        qty_delta: line.curr_qty - line.prev_qty,
      }));

    if (!note || changes.length === 0) return;

    axios
      .post("/work_order/", {
        changes: changes,
        build: build.serial,
        note: note,
        type: "RTF",
        version: version.diff,
      })
      .then(console.log)
      .catch(console.error);

    setShow(false);
  };

  return (
    <Dialog onClose={setShow} open={show}>
      <DialogTitle>Retrofit assembly</DialogTitle>
      <DialogDescription>Add notes, if any.</DialogDescription>
      <DialogBody>
        <Input type="text" onChange={(e) => setNote(e.target.value)} />
        <Button className="mt-4" onClick={createWorkOrder}>
          Create work order
        </Button>
      </DialogBody>
    </Dialog>
  );
}

function Quantity({
  l,
  onChange,
}: {
  l: RetrofitLine;
  onChange: (part_id: number, qty: number) => void;
}) {
  if (!l.selected) {
    return <>{l.curr_qty}</>;
  }

  return (
    <div className="flex flex-row items-center">
      {l.prev_qty !== l.curr_qty ? <del>{l.prev_qty}</del> : l.prev_qty}
      <Input
        type="number"
        className="ml-2 w-16"
        value={l.curr_qty}
        onChange={(e) => onChange(l.part.id, Number(e.target.value))}
      />
    </div>
  );
}

export function RetrofitBom({
  build,
  compareTo,
  showFinalize,
  setShowFinalize,
}: {
  build: Build;
  compareTo: Version;
  showFinalize: boolean;
  setShowFinalize: (show: boolean) => void;
}) {
  const { lines, setLines } = useContext(RetrofitContext);

  useEffect(() => {
    axios
      .get(`/diff/?diff1_id=${compareTo.diff}&diff2_id=${build.diff}`)
      .then((res) => {
        setLines(
          res.data.map((line: DiffLine) => ({
            ...line,
            selected: line.prev_qty !== line.curr_qty,
            curr_qty: line.curr_qty,
          }))
        );
      })
      .catch(console.error);
  }, [build, compareTo]);

  if (!lines) return <></>;

  const checkLine = (part_id: number) => {
    setLines(
      lines.map((line) => {
        if (line.part.id === part_id) {
          return {
            ...line,
            selected: !line.selected,
            curr_qty: line.selected ? line.prev_qty : line.curr_qty,
          };
        }
        return line;
      })
    );
  };

  const updateQty = (part_id: number, qty: number) => {
    setLines(
      lines.map((line) => {
        if (line.part.id === part_id) {
          return { ...line, curr_qty: qty };
        }
        return line;
      })
    );
  };

  return (
    <Table>
      <FinalizeDialog
        show={showFinalize}
        setShow={setShowFinalize}
        retrofitBom={lines}
        build={build}
        version={compareTo}
      />
      <TableHead>
        <TableRow>
          {HEADERS.map((head) => (
            <TableHeader key={head}>{head}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {lines.map((l) => (
          <TableRow key={l.part.id} className={tableRowClassName(l)}>
            <TableCell>
              <Checkbox
                checked={l.selected}
                onChange={() => checkLine(l.part.id)}
              />
            </TableCell>
            <TableCell>{l.part.num}</TableCell>
            <TableCell>
              {l.part.summary !== "null" ? l.part.summary : ""}
            </TableCell>
            <TableCell>{l.part.name}</TableCell>
            <TableCell>{l.part.version}</TableCell>
            <TableCell>
              <Quantity l={l} onChange={updateQty} />
            </TableCell>
            <TableCell>{USDollar.format(l.part.price)}</TableCell>
            <TableCell>{l.part.note}</TableCell>
            <TableCell>
              <Source url={l.part.url} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
