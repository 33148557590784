import axios from "axios";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";

import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

axios.defaults.baseURL =
  process.env.REACT_APP_API_HOST || "https://gettracely.com/api/";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (request) => {
    request.headers["Authorization"] =
      "Bearer " + localStorage.getItem("access");
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    // Avoid retrying the refresh token request itself
    if (originalRequest.url.includes("/account/refresh/")) {
      return Promise.reject(error); // If refresh fails, do not retry
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried

      // Handle token refresh
      return axios
        .post("/account/refresh/", {
          refresh: localStorage.getItem("refresh"),
        })
        .then((response) => {
          localStorage.setItem("access", response.data.access);
          // Update the original request with the new access token
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${response.data.access}`;
          return axios.request(originalRequest); // Retry the original request
        })
        .catch((error) => {
          // Handle refresh failure
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          window.location.href = "/login";
          return Promise.reject(error); // Reject the promise after failed refresh
        });
    }

    return Promise.reject(error); // Reject promise for all other errors
  }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
