import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/16/solid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { BomContext } from "../providers/BomProvider";
import { ProjectContext } from "../providers/ProjectProvider";
import { UserContext } from "../providers/UserProvider";
import { Unit } from "../types/project";
import { Avatar } from "./catalyst/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "./catalyst/dropdown";
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "./catalyst/sidebar";
import { Text } from "./catalyst/text";

export default function LeftSidebar() {
  const { user } = useContext(UserContext);
  const { design, unit, setDesign, setUnit } = useContext(BomContext);
  const { projects, project, setProject } = useContext(ProjectContext);

  const [units, setUnits] = useState<Unit[] | undefined>(undefined);

  const getUnit = (serial: string) => {
    axios
      .get(`/unit/${serial}/`)
      .then((res) => setUnit(res.data))
      .catch(console.error);
  };

  const getDesign = (id: number) => {
    axios
      .get(`/boms/${id}/`)
      .then((res) => setDesign(res.data))
      .catch(console.error);
  };

  const getUnits = () => {
    axios
      .get("/unit/")
      .then((res) => {
        const units = res.data;
        setUnits(units);
        getUnit(units[0].serial);
      })
      .catch(console.error);
  };

  const signOut = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    window.location.href = "/login";
  };

  useEffect(() => {
    if (!units) {
      getUnits();
    }
  });

  if (!projects || !project) return <></>;

  return (
    <Sidebar>
      <SidebarHeader>
        {/* Project dropdown */}
        <Dropdown>
          <DropdownButton as={SidebarItem} className="lg:mb-2.5">
            <Avatar src={project.avatar} />
            <SidebarLabel>{project.name}</SidebarLabel>
            <ChevronDownIcon />
          </DropdownButton>
          <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
            {projects.map((project) => (
              <DropdownItem
                key={project.id}
                onClick={() => setProject(project)}
              >
                <DropdownLabel>{project.name}</DropdownLabel>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </SidebarHeader>

      <SidebarBody>
        <SidebarSection className="max-lg:hidden">
          <SidebarHeading>Units</SidebarHeading>
          {project.units.map((u) => (
            <SidebarItem
              key={u.serial}
              onClick={() => getUnit(u.serial)}
              className={
                unit?.serial === u.serial
                  ? "bg-zinc-200 rounded-lg"
                  : "cursor-pointer"
              }
            >
              <Text className="font-normal">{u.name}</Text>
            </SidebarItem>
          ))}
        </SidebarSection>

        <SidebarSection className="max-lg:hidden">
          <div className="flex flex-row justify-between">
            <SidebarHeading>BOMs</SidebarHeading>
          </div>
          {project?.designs.map((d) => (
            <SidebarItem
              key={d.id}
              onClick={() => getDesign(d.id)}
              className={
                design?.id === d.id
                  ? "bg-zinc-200 rounded-lg"
                  : "cursor-pointer"
              }
            >
              <Text className="font-normal">{d.name}</Text>
            </SidebarItem>
          ))}
        </SidebarSection>
      </SidebarBody>

      <SidebarFooter className="max-lg:hidden">
        <Dropdown>
          <DropdownButton as={SidebarItem}>
            <span className="flex min-w-0 items-center gap-3">
              <Avatar src={user?.avatar} className="size-10" alt="" />
              <span className="min-w-0">
                <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                  {user?.first_name} {user?.last_name}
                </span>
                <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                  {user?.email}
                </span>
              </span>
            </span>
            <ChevronUpIcon />
          </DropdownButton>
          <DropdownMenu className="min-w-64" anchor="top start">
            <DropdownItem onClick={signOut}>
              <ArrowRightStartOnRectangleIcon />
              <DropdownLabel>Sign out</DropdownLabel>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </SidebarFooter>
    </Sidebar>
  );
}
