import axios from "axios";
import { ReactNode, createContext, useEffect, useState } from "react";
import { User } from "../types/user";

type ContextProps = {
  user?: User;
};

export const UserContext = createContext<ContextProps>({
  user: undefined,
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | undefined>(undefined);

  const getUser = async () => {
    if (!user) {
      try {
        const res = await axios.get("/account/");
        setUser(res.data);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (!user && !window.location.pathname.includes("/login")) {
      getUser();
    }
  }, []); // Empty dependency array ensures this only runs once on mount

  const context = {
    user: user,
  };

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};
