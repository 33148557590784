import axios from "axios";
import { useState } from "react";
import { Button } from "../components/catalyst/button";
import { Field, FieldGroup, Label } from "../components/catalyst/fieldset";
import { Heading } from "../components/catalyst/heading";
import { Input } from "../components/catalyst/input";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = (e?: any) => {
    // Separate instance so it doesn't add auth header
    const instance = axios.create();

    instance
      .post("/account/access/", {
        email: email,
        password: password,
      })
      .then((response) => {
        localStorage.setItem("access", response.data.access);
        localStorage.setItem("refresh", response.data.refresh);
        window.location.href = "/";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <div className="grow lg:rounded-lg lg:bg-white lg:p-2 lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5 dark:lg:bg-zinc-900 dark:lg:ring-white/10">
          <FieldGroup className="flex flex-col items-center m-4">
            <Heading>Login</Heading>
            <Field>
              <Label>Email</Label>
              <Input
                name="email"
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </Field>
            <Field>
              <Label>Password</Label>
              <Input
                type="password"
                name="password"
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </Field>
            <Button onClick={login}>Login</Button>
          </FieldGroup>
        </div>
      </div>
    </div>
  );
}
