import { BomProvider } from "../providers/BomProvider";

import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useContext, useState } from "react";
import DesignSidebar from "../components/DesignSidebar";
import LeftSidebar from "../components/LeftSidebar";
import UnitSidebar from "../components/UnitSidebar";
import { Badge } from "../components/catalyst/badge";
import { Button } from "../components/catalyst/button";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../components/catalyst/dropdown";
import { Navbar } from "../components/catalyst/navbar";
import { SidebarItem } from "../components/catalyst/sidebar";
import { SidebarLayout } from "../components/catalyst/sidebar-layout";
import { Text } from "../components/catalyst/text";
import { DiffBom } from "../components/views/DiffBom";
import { RetrofitBom } from "../components/views/RetrofitBom";
import { BuildBom } from "../components/views/UnitBom";
import DesignBom from "../components/views/VersionBom";
import { BomContext } from "../providers/BomProvider";
import ProjectProvider from "../providers/ProjectProvider";
import { RetrofitProvider } from "../providers/RetrofitProvider";
import { Build, Design, Version } from "../types/project";

function BuildView({ build }: { build: Build }) {
  const [compareTo, setCompareTo] = useState<Version | undefined>(undefined);
  const [retrofiting, setRetrofiting] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [showAll, setShowAll] = useState(false);

  let bom = <></>;
  if (compareTo && retrofiting) {
    bom = (
      <RetrofitBom
        build={build}
        compareTo={compareTo}
        setShowFinalize={setOpen}
        showFinalize={open}
      />
    );
  } else if (!compareTo) {
    bom = <BuildBom build={build} showAll={showAll} />;
  } else if (compareTo) {
    bom = <DiffBom diff1_id={compareTo.diff} diff2_id={build.diff} />;
  }

  return (
    <div className="grow lg:rounded-lg lg:bg-white lg:p-4 lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5 dark:lg:bg-zinc-900 dark:lg:ring-white/10">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-3">
            <Badge>{build.design.name}</Badge>
            <Badge color="yellow">{build.version.tag}</Badge>
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <Text>{compareTo ? compareTo.tag : "Compare to..."}</Text>
                <ChevronDownIcon />
              </DropdownButton>
              <DropdownMenu anchor="bottom start">
                <DropdownItem onClick={() => setCompareTo(undefined)}>
                  <DropdownLabel>Compare to...</DropdownLabel>
                </DropdownItem>

                {build.design.versions?.map((v) => (
                  <DropdownItem key={v.diff} onClick={() => setCompareTo(v)}>
                    <DropdownLabel>{v.tag}</DropdownLabel>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            {compareTo && !retrofiting && (
              <Button
                className="w-18 cursor-pointer"
                onClick={() => setRetrofiting(true)}
              >
                Retrofit
              </Button>
            )}
            {retrofiting && (
              <>
                <Button
                  className="w-18 cursor-pointer"
                  onClick={() => setOpen(true)}
                >
                  Finalize
                </Button>
                <Button
                  className="w-18 cursor-pointer"
                  color="red"
                  onClick={() => setRetrofiting(false)}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
          {!compareTo && (
            <button
              type="button"
              className={`mt-2 group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ${
                showAll ? "bg-indigo-600" : "bg-gray-200"
              }`}
              role="switch"
              aria-checked={showAll}
              onClick={() => setShowAll(!showAll)}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className="pointer-events-none absolute h-full w-full rounded-md bg-white"
              ></span>
              <span
                aria-hidden="true"
                className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out ${
                  showAll ? "bg-indigo-600" : "bg-gray-200"
                }`}
              ></span>
              <span
                aria-hidden="true"
                className={`pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out ${
                  showAll ? "translate-x-5" : "translate-x-0"
                }`}
              ></span>
            </button>
          )}
        </div>
        {bom}
      </div>
    </div>
  );
}

function DesignView({ design }: { design: Design }) {
  const version = design.versions[0];
  const [compareTo, setCompareTo] = useState<Version | undefined>(undefined);

  let bom = <></>;
  if (!compareTo) {
    bom = <DesignBom design={design} />;
  } else if (compareTo) {
    bom = <DiffBom diff1_id={compareTo.diff} diff2_id={version.diff} />;
  }

  return (
    <div className="grow lg:rounded-lg lg:bg-white lg:p-4 lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5 dark:lg:bg-zinc-900 dark:lg:ring-white/10">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row space-x-3">
          <Badge>{design.name}</Badge>
          <Badge color="yellow">{version.tag}</Badge>
          <Dropdown>
            <DropdownButton as={SidebarItem}>
              <Text>{compareTo ? compareTo.tag : "Compare to..."}</Text>
              <ChevronDownIcon />
            </DropdownButton>
            <DropdownMenu anchor="bottom start">
              <DropdownItem onClick={() => setCompareTo(undefined)}>
                <DropdownLabel>Compare to...</DropdownLabel>
              </DropdownItem>

              {design.versions?.map((v) => (
                <DropdownItem key={v.diff} onClick={() => setCompareTo(v)}>
                  <DropdownLabel>{v.tag}</DropdownLabel>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        {bom}
      </div>
    </div>
  );
}

function Layout() {
  const { design, unit } = useContext(BomContext);

  let rightSidebar = null;
  if (design) {
    rightSidebar = <DesignSidebar />;
  } else if (unit) {
    rightSidebar = <UnitSidebar />;
  }

  return (
    <SidebarLayout
      navbar={<Navbar />}
      leftSidebar={<LeftSidebar />}
      rightSidebar={rightSidebar}
    >
      <div className="flex flex-col space-y-4">
        {unit?.builds.map((build) => (
          <BuildView build={build} />
        ))}
        {design && <DesignView design={design} />}
      </div>
    </SidebarLayout>
  );
}

export default function BomPage() {
  return (
    <div className="">
      <ProjectProvider>
        <BomProvider>
          <RetrofitProvider>
            <Layout />
          </RetrofitProvider>
        </BomProvider>
      </ProjectProvider>
    </div>
  );
}
