import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { Project } from "../types/project";

type ContextProps = {
  projects?: Project[];
  project?: Project;
  setProject: (p?: Project) => void;
  refreshProjects: () => void;
};

export const ProjectContext = createContext<ContextProps>({
  projects: undefined,
  project: undefined,
  setProject: () => {},
  refreshProjects: () => {},
});

export const ProjectProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [projects, setProjects] = useState<Project[] | undefined>(undefined);
  const [project, setProject] = useState<Project | undefined>(undefined);

  const refreshProjects = (shouldSetProject: boolean = false) => {
    axios
      .get("/project/")
      .then((res) => {
        setProjects(res.data);
        if (shouldSetProject) {
          setProject(res.data[0]);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (!projects) {
      refreshProjects(true);
    }
  }, [project]);

  return (
    <ProjectContext.Provider
      value={{ projects, project, setProject, refreshProjects }}
    >
      {project && children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
