import { LinkIcon } from "@heroicons/react/24/outline";
import { DiffLine } from "../../types/project";

export const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function Source({ url }: { url: string | undefined }) {
  if (!url) {
    return <></>;
  }

  return (
    <a href={url} target="_blank" rel="noreferrer">
      <LinkIcon className="h-5 w-5" />
    </a>
  );
}

export function tableRowClassName(l: DiffLine) {
  const was_added = l.prev_qty === 0 && l.curr_qty > 0;
  const was_removed = l.curr_qty === 0 && l.prev_qty > 0;
  const was_changed = l.prev_qty !== l.curr_qty && !was_added && !was_removed;

  if (was_added) {
    return "bg-green-50 dark:bg-green-800 hover:bg-green-100 dark:hover:bg-green-700";
  } else if (was_removed) {
    return "bg-red-50 dark:bg-red-800 hover:bg-red-100 dark:hover:bg-red-700";
  } else if (was_changed) {
    return "bg-yellow-50 dark:bg-yellow-800 hover:bg-yellow-100 dark:hover:bg-yellow-700";
  }

  return "dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700";
}
