import { ArrowPathIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { Build, Part, Unit, UnitLine } from "../../types/project";
import { Button } from "../catalyst/button";
import {
  Dialog,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../catalyst/dialog";
import { Input } from "../catalyst/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import { Source, USDollar } from "./Common";

const HEADERS = [
  "",
  "Installed",
  "Part No.",
  "Summary",
  "Name",
  "Version",
  "Qty",
  "Price",
  "Note",
  "Source",
];

function ReplacePartDialog({
  show,
  setShow,
  part,
  build,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  part: Part;
  build: Build;
}) {
  const [note, setNote] = useState<string>("");

  const createWorkOrder = (part: Part) => {
    axios
      .post("/work_order/", {
        changes: [
          {
            part: part.id,
            qty_delta: 0,
          },
        ],
        build: build.serial,
        note: note || "No note.",
        type: "RPL",
        version: build.version.id,
      })
      .then(console.log)
      .catch(console.error);

    setShow(false);
  };

  return (
    <Dialog onClose={setShow} open={show}>
      <DialogTitle>Replace part</DialogTitle>
      <DialogDescription>Add notes, if any.</DialogDescription>
      <DialogBody>
        <Input type="text" onChange={(e) => setNote(e.target.value)} />
        <Button className="mt-4" onClick={() => createWorkOrder(part)}>
          Create work order
        </Button>
      </DialogBody>
    </Dialog>
  );
}

export function BuildBom({
  build,
  showAll,
}: {
  build: Build;
  showAll?: boolean;
}) {
  const [bom, setBom] = useState<UnitLine[] | undefined>(undefined);
  const [showReplace, setShowReplace] = useState(false);

  useEffect(() => {
    axios
      .get(`/bom/?serial=${build.serial}`)
      .then((res) => setBom(res.data))
      .catch(console.error);
  }, [build]);

  if (!bom) return <></>;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {HEADERS.map((head) => (
            <TableHeader key={head}>{head}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {bom
          .filter((l) => showAll || l.part.important)
          .map((l) => (
            <TableRow key={l.part.id}>
              <TableCell>
                <ArrowPathIcon
                  className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-pointer"
                  onClick={() => setShowReplace(true)}
                />
                <ReplacePartDialog
                  show={showReplace}
                  setShow={setShowReplace}
                  part={l.part}
                  build={build}
                />
              </TableCell>
              <TableCell className="w-28">
                {l.installed && (
                  <ReactTimeAgo date={new Date(l.installed)} locale="en-US" />
                )}
                {!l.installed && "n/a"}
              </TableCell>
              <TableCell>{l.part.num}</TableCell>
              <TableCell>
                {l.part.summary !== "null" ? l.part.summary : ""}
              </TableCell>
              <TableCell>{l.part.name}</TableCell>
              <TableCell>{l.part.version}</TableCell>
              <TableCell>{l.qty}</TableCell>
              <TableCell>{USDollar.format(l.part.price)}</TableCell>
              <TableCell>{l.part.note}</TableCell>
              <TableCell>
                <Source url={l.part.url} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export function UnitBom({ unit }: { unit: Unit }) {
  return (
    <div>
      {unit.builds.map((build) => (
        <div
          key={build.serial}
          className="grow lg:rounded-lg lg:bg-white lg:p-4 lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5 dark:lg:bg-zinc-900 dark:lg:ring-white/10"
        >
          <BuildBom build={build} />
        </div>
      ))}
    </div>
  );
}
