import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Spinner from "./components/Spinner";
import BomPage from "./pages/BomPage";
import LoginPage from "./pages/LoginPage";
import { UserContext, UserProvider } from "./providers/UserProvider";

function Main() {
  const { user } = useContext(UserContext);

  if (!user && !window.location.pathname.includes("/login")) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<BomPage />} />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
}

export default function App() {
  return (
    <div className="flex flex-col h-screen">
      <UserProvider>
        <Main />
      </UserProvider>
    </div>
  );
}
