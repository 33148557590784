import axios from "axios";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Design, Unit, Version } from "../types/project";
import { ProjectContext } from "./ProjectProvider";

type ContextProps = {
  design?: Design;
  unit?: Unit;
  version?: Version;
  compareTo?: Version;

  setDesign: (e: Design) => void;
  setUnit: (e: Unit) => void;
  setVersion: (v: Version) => void;
  setCompareTo: (v: Version | undefined) => void;
};

export const BomContext = createContext<ContextProps>({
  design: undefined,
  unit: undefined,
  version: undefined,
  compareTo: undefined,

  setDesign: () => {},
  setUnit: () => {},
  setVersion: () => {},
  setCompareTo: () => {},
});

export const BomProvider = ({ children }: { children: ReactNode }) => {
  const { project } = useContext(ProjectContext);

  const [design, setDesign] = useState<Design | undefined>(undefined);
  const [unit, setUnit] = useState<Unit | undefined>(undefined);
  const [version, setVersion] = useState<Version | undefined>(undefined);
  const [compareTo, setCompareTo] = useState<Version | undefined>(undefined);

  const selectUnit = (unit: Unit) => {
    setUnit(unit);
    setDesign(undefined);
    setVersion(undefined);
    setCompareTo(undefined);
  };

  const selectDesign = (design: Design) => {
    setDesign(design);
    setUnit(undefined);
    setVersion(design.versions[0]);
    setCompareTo(undefined);
  };

  useEffect(() => {
    if (project && !design && !unit) {
      const serial = project.units[0].serial;
      axios
        .get(`/unit/${serial}/`)
        .then((res: any) => setUnit(res.data))
        .catch((err: any) => console.error(err));
    }
  }, [project]);

  if (!project) {
    return <></>;
  }

  const context = {
    design: design,
    unit: unit,
    version: version,
    compareTo: compareTo,

    setDesign: selectDesign,
    setUnit: selectUnit,
    setVersion: setVersion,
    setCompareTo: setCompareTo,
  };

  return <BomContext.Provider value={context}>{children}</BomContext.Provider>;
};
