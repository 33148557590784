import { ReactNode, createContext, useState } from "react";
import { RetrofitLine } from "../types/project";

type ContextProps = {
  retrofiting: boolean;
  lines: RetrofitLine[] | undefined;

  setRetrofiting: (v: boolean) => void;
  setLines: (v: RetrofitLine[] | undefined) => void;
};

export const RetrofitContext = createContext<ContextProps>({
  retrofiting: false,
  lines: undefined,

  setRetrofiting: () => {},
  setLines: () => {},
});

export const RetrofitProvider = ({ children }: { children: ReactNode }) => {
  const [retrofiting, setRetrofiting] = useState<boolean>(false);
  const [lines, setLines] = useState<RetrofitLine[] | undefined>(undefined);

  const context = {
    retrofiting,
    lines,

    setRetrofiting,
    setLines,
  };

  return (
    <RetrofitContext.Provider value={context}>
      {children}
    </RetrofitContext.Provider>
  );
};
